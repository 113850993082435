.control-panel {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 320px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    padding: 12px 24px;
    margin: 20px;
    font-size: 13px;
    line-height: 2;
    color: #6b6b76;
    outline: none;
    text-transform: uppercase;
  }
  
  labelmap {
    display: inline-block;
    width: 100px;
  }
  
  inputmap {
    margin-left: 20px;
    width: 160px;
  }
  
  .tooltip1 {
    position: absolute;
    margin: 8px;
    padding: 4px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 10px;
    z-index: 9;
    pointer-events: none;
  }